
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46pageSlug_93A_45tlTcaoEBX5o6F0i4ctsgsVDWg8aAGNWqsujOT7QX4Meta } from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/cms/pages/[...pageSlug].vue?macro=true";
import { default as activateg9HcacLdrYxW54z6XQ2JKf8R9D0sHvbIJN3Q4lagu30Meta } from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/account/pages/account/activate.vue?macro=true";
import { default as indexktQm4vytKHbozQoWcfq2p5EgBh3pq8FNFsuG0NolrT0Meta } from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/account/pages/account/index.vue?macro=true";
import { default as _91securityKey_937TVCGU733iomVrj5GRNyWscbntyxRWEYD4n_45_45EZjCmQMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/account/pages/account/order/[orderNumber]/[securityKey].vue?macro=true";
import { default as newdwCQ_453NdFjm_45Rs3VoChx_0lwEOQ_fWNlE2PTY8fIUq0Meta } from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/account/pages/account/password/new.vue?macro=true";
import { default as watchlistZyw5HGmsPcoJq9hP4ZuB8hzBL_45xO_451U_45k1_cK_4571OawMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/account/pages/account/watchlist.vue?macro=true";
import { default as indexS4hroM2QzqWDZ14T4_vXWHWWDYBRDJiaaqPC_iZD7q0Meta } from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/builder/pages/admin/index.vue?macro=true";
import { default as edit4gcqBrtBB8pvv9xIDJB4PxyKwkYliTanzSfHt5PDTgEMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/builder/pages/admin/raffle/[id]/edit.vue?macro=true";
import { default as index5gmTiYMPkARifeDIbqTW8gBZh1vBDim1tJSXUzug_FgMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/builder/pages/admin/raffle/[id]/index.vue?macro=true";
import { default as addgiaaMT67_450Cg5x9KWAirg_457U7ZjwMKrkqsr7Nq_45vuXoMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/builder/pages/admin/raffle/add.vue?macro=true";
import { default as rafflesrmkGtt2_453G0120w6_459DsAcAHh4O5J9QUBrEb65hgui4Meta } from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/builder/pages/admin/raffles.vue?macro=true";
import { default as faqIqamYZ8hdhxYeFD5_FbXze_xRWR0ntHywR7Ea9a2iN4Meta } from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue?macro=true";
import { default as infoiwRX2foP0EGis5V1ZY8pIZYWK_MKgRP7CZZ_45KX2yhdcMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue?macro=true";
import { default as _91showGroupSlug_93GzuVOahb66CDaIpIjHz8_W2I_45nu_ZyFuXtXGfb2nRcwMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue?macro=true";
import { default as ordersTSPA4d_iQu5RyTdlB2EkTL0c_45DHkjdMyx5sWQuuOmHAMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/orders.vue?macro=true";
import { default as programx5lUo6IihRijtfM0aZ3kpxjxm1dpSYR0h6_ODQek8tEMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue?macro=true";
import { default as shopXbpY6cRTb9rGoSivEnh5lU3S_S_wP_vl8OmRPfyid_kMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue?macro=true";
import { default as _91showSlug_93OB_udzBKqIy6__yKs22iQzttg5gQON7TZANmS68cKFcMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue?macro=true";
import { default as subscription4ue69aOu7ygUgRJ1ojX69_45ON_45jqMgR_Wgfdt1_9iMmUMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/subscription.vue?macro=true";
import { default as vouchersl5XWUMiFyt3kJX74qNQFYCJ2Pk7pQZiNVNO_45oIW8UjcMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue?macro=true";
import { default as _91cinemaSlug_93Av6wUaOVqHXL4ss6P_45V_cRCw3GuuigS0R9ECfXUZj1IMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue?macro=true";
import { default as indexFDEFeYZrSbxLwxu5VvQMldOZ3wWiAOcogPZENE92QPUMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/index.vue?macro=true";
import { default as cinemasC_Wd_iFVIXiNJ_45T841IcuGqe6XkqYe8O3VDZ_XwN95cMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinemas.vue?macro=true";
import { default as cities_45MqIC1pHe47_WHI6X0tM9GoRnX_76rB1KQ6MHlHZ7NgMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cities.vue?macro=true";
import { default as indexmyuoqeYtux1YSTX_qJcFPNS3kwNcnYNpJ0pOimY_SmYMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/index.vue?macro=true";
import { default as _91movieSlug_93umXShHNfH57nD3gr6V6Z_XJlRe_0gUY29pLUDZr_kywMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/movie/[movieSlug].vue?macro=true";
import { default as indexTtPAnlEA_qa3KfA_e9hGFp0Wo7GlK1gvLo_45vH6J5ZUwMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/movies/genre/[genreSlug]/index.vue?macro=true";
import { default as _91genreSlug_93wfa3WstFBQAnLx1h_9XoZrENQFmPKYC8s_45oaJ30UJtsMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/movies/genre/[genreSlug].vue?macro=true";
import { default as genresQsIBm4t3EsL6odeAYbinx9i_45Qb4HMZx5lAUPTYAavVIMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/movies/genres.vue?macro=true";
import { default as indexL1xytPhSILyx_45ro7ReHIjWNifDdWj2QdZ3ViiEfeNzQMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/movies/index.vue?macro=true";
import { default as _91personSlug_93JV_45m2of6eAWFgnz0AIXHJaMjUL3XXSdtqCY9CQbdlakMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/person/[personSlug].vue?macro=true";
import { default as personsFSlXKCI8C2MZ5JipeV_454_45HBg_45X_45EMwSkEl_P_Y2iUFsMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/persons.vue?macro=true";
import { default as searchRxNYQ3ut3w3agzE4Xta8o8hn3By4qyqDxOklYqYDF4AMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/search.vue?macro=true";
import { default as vouchersUr_45wS_45fwqYZUpIkgd2UotkdS9_8ljRSZl3_dvXPOgMAMeta } from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/vouchers.vue?macro=true";
export default [
  {
    name: "pageSlug___de",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/cms/pages/[...pageSlug].vue")
  },
  {
    name: "pageSlug___en",
    path: "/en/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/cms/pages/[...pageSlug].vue")
  },
  {
    name: "pageSlug___es",
    path: "/es/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/cms/pages/[...pageSlug].vue")
  },
  {
    name: "account-activate___de",
    path: "/konto/aktivieren",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/account/pages/account/activate.vue")
  },
  {
    name: "account-activate___en",
    path: "/en/account/activate",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/account/pages/account/activate.vue")
  },
  {
    name: "account-activate___es",
    path: "/es/cuenta/activar",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/account/pages/account/activate.vue")
  },
  {
    name: "account___de",
    path: "/konto",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/account/pages/account/index.vue")
  },
  {
    name: "account___en",
    path: "/en/account",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/account/pages/account/index.vue")
  },
  {
    name: "account___es",
    path: "/es/cuenta",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/account/pages/account/index.vue")
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/konto/bestellung/:orderNumber()/:securityKey()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/account/pages/account/order/[orderNumber]/[securityKey].vue")
  },
  {
    name: "account-order-orderNumber-securityKey___en",
    path: "/en/account/order/:orderNumber()/:securityKey()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/account/pages/account/order/[orderNumber]/[securityKey].vue")
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/es/cuenta/compra/:orderNumber()/:securityKey()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/account/pages/account/order/[orderNumber]/[securityKey].vue")
  },
  {
    name: "account-password-new___de",
    path: "/konto/passwort/neu",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/account/pages/account/password/new.vue")
  },
  {
    name: "account-password-new___en",
    path: "/en/account/password/new",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/account/pages/account/password/new.vue")
  },
  {
    name: "account-password-new___es",
    path: "/es/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/account/pages/account/password/new.vue")
  },
  {
    name: "account-watchlist___de",
    path: "/konto/merkliste",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/account/pages/account/watchlist.vue")
  },
  {
    name: "account-watchlist___en",
    path: "/en/account/watchlist",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/account/pages/account/watchlist.vue")
  },
  {
    name: "account-watchlist___es",
    path: "/es/cuenta/marcadores",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/account/pages/account/watchlist.vue")
  },
  {
    name: "admin___de",
    path: "/admin",
    meta: indexS4hroM2QzqWDZ14T4_vXWHWWDYBRDJiaaqPC_iZD7q0Meta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/builder/pages/admin/index.vue")
  },
  {
    name: "admin___en",
    path: "/en/admin",
    meta: indexS4hroM2QzqWDZ14T4_vXWHWWDYBRDJiaaqPC_iZD7q0Meta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/builder/pages/admin/index.vue")
  },
  {
    name: "admin___es",
    path: "/es/admin",
    meta: indexS4hroM2QzqWDZ14T4_vXWHWWDYBRDJiaaqPC_iZD7q0Meta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/builder/pages/admin/index.vue")
  },
  {
    name: "admin-raffle-id-edit___de",
    path: "/admin/raffle/:id()/edit",
    meta: edit4gcqBrtBB8pvv9xIDJB4PxyKwkYliTanzSfHt5PDTgEMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/builder/pages/admin/raffle/[id]/edit.vue")
  },
  {
    name: "admin-raffle-id-edit___en",
    path: "/en/admin/raffle/:id()/edit",
    meta: edit4gcqBrtBB8pvv9xIDJB4PxyKwkYliTanzSfHt5PDTgEMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/builder/pages/admin/raffle/[id]/edit.vue")
  },
  {
    name: "admin-raffle-id-edit___es",
    path: "/es/admin/raffle/:id()/edit",
    meta: edit4gcqBrtBB8pvv9xIDJB4PxyKwkYliTanzSfHt5PDTgEMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/builder/pages/admin/raffle/[id]/edit.vue")
  },
  {
    name: "admin-raffle-id___de",
    path: "/admin/raffle/:id",
    meta: index5gmTiYMPkARifeDIbqTW8gBZh1vBDim1tJSXUzug_FgMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/builder/pages/admin/raffle/[id]/index.vue")
  },
  {
    name: "admin-raffle-id___en",
    path: "/en/admin/raffle/:id",
    meta: index5gmTiYMPkARifeDIbqTW8gBZh1vBDim1tJSXUzug_FgMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/builder/pages/admin/raffle/[id]/index.vue")
  },
  {
    name: "admin-raffle-id___es",
    path: "/es/admin/raffle/:id",
    meta: index5gmTiYMPkARifeDIbqTW8gBZh1vBDim1tJSXUzug_FgMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/builder/pages/admin/raffle/[id]/index.vue")
  },
  {
    name: "admin-raffle-add___de",
    path: "/admin/raffle/add",
    meta: addgiaaMT67_450Cg5x9KWAirg_457U7ZjwMKrkqsr7Nq_45vuXoMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/builder/pages/admin/raffle/add.vue")
  },
  {
    name: "admin-raffle-add___en",
    path: "/en/admin/raffle/add",
    meta: addgiaaMT67_450Cg5x9KWAirg_457U7ZjwMKrkqsr7Nq_45vuXoMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/builder/pages/admin/raffle/add.vue")
  },
  {
    name: "admin-raffle-add___es",
    path: "/es/admin/raffle/add",
    meta: addgiaaMT67_450Cg5x9KWAirg_457U7ZjwMKrkqsr7Nq_45vuXoMeta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/builder/pages/admin/raffle/add.vue")
  },
  {
    name: "admin-raffles___de",
    path: "/admin/raffles",
    meta: rafflesrmkGtt2_453G0120w6_459DsAcAHh4O5J9QUBrEb65hgui4Meta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/builder/pages/admin/raffles.vue")
  },
  {
    name: "admin-raffles___en",
    path: "/en/admin/raffles",
    meta: rafflesrmkGtt2_453G0120w6_459DsAcAHh4O5J9QUBrEb65hgui4Meta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/builder/pages/admin/raffles.vue")
  },
  {
    name: "admin-raffles___es",
    path: "/es/admin/raffles",
    meta: rafflesrmkGtt2_453G0120w6_459DsAcAHh4O5J9QUBrEb65hgui4Meta || {},
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/builder/pages/admin/raffles.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug___de",
    path: "/kino/:citySlug()/:cinemaSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue"),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___de",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___de",
    path: "info",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___de",
    path: "film/:showGroupSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-orders___de",
    path: "bestellungen",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/orders.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___de",
    path: "vorstellungen",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___de",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___de",
    path: "vorstellung/:showSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-subscription___de",
    path: "abo",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/subscription.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___de",
    path: "gutscheine",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue")
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug___en",
    path: "/en/cinema/:citySlug()/:cinemaSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue"),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___en",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___en",
    path: "info",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___en",
    path: "movie/:showGroupSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-orders___en",
    path: "orders",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/orders.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___en",
    path: "program",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___en",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___en",
    path: "show/:showSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-subscription___en",
    path: "subscription",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/subscription.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___en",
    path: "vouchers",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue")
  }
]
  },
  {
    name: "cinema-citySlug-cinemaSlug___es",
    path: "/es/cine/:citySlug()/:cinemaSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug].vue"),
    children: [
  {
    name: "cinema-citySlug-cinemaSlug-faq___es",
    path: "faq",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/faq.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-info___es",
    path: "informacion",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/info.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-movie-showGroupSlug___es",
    path: "movie/:showGroupSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/movie/[showGroupSlug].vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-orders___es",
    path: "pedidos",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/orders.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-program___es",
    path: "sesiones",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/program.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-shop___es",
    path: "shop",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/shop.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-show-showSlug___es",
    path: "evento/:showSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/show/[showSlug].vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-subscription___es",
    path: "suscripcion",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/subscription.vue")
  },
  {
    name: "cinema-citySlug-cinemaSlug-vouchers___es",
    path: "codigos",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/[cinemaSlug]/vouchers.vue")
  }
]
  },
  {
    name: "cinema-citySlug___de",
    path: "/cinema/:citySlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/index.vue")
  },
  {
    name: "cinema-citySlug___en",
    path: "/en/cinema/:citySlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/index.vue")
  },
  {
    name: "cinema-citySlug___es",
    path: "/es/cinema/:citySlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinema/[citySlug]/index.vue")
  },
  {
    name: "cinemas___de",
    path: "/kinos",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinemas.vue")
  },
  {
    name: "cinemas___en",
    path: "/en/cinemas",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinemas.vue")
  },
  {
    name: "cinemas___es",
    path: "/es/cines",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cinemas.vue")
  },
  {
    name: "cities___de",
    path: "/staedte",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cities.vue")
  },
  {
    name: "cities___en",
    path: "/en/cities",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cities.vue")
  },
  {
    name: "cities___es",
    path: "/es/ciudades",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/cities.vue")
  },
  {
    name: "index___de",
    path: "/",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/index.vue")
  },
  {
    name: "movie-movieSlug___de",
    path: "/film/:movieSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/movie/[movieSlug].vue")
  },
  {
    name: "movie-movieSlug___en",
    path: "/en/movie/:movieSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/movie/[movieSlug].vue")
  },
  {
    name: "movie-movieSlug___es",
    path: "/es/pelicula/:movieSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/movie/[movieSlug].vue")
  },
  {
    name: _91genreSlug_93wfa3WstFBQAnLx1h_9XoZrENQFmPKYC8s_45oaJ30UJtsMeta?.name,
    path: "/filme/genre/:genreSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/movies/genre/[genreSlug].vue"),
    children: [
  {
    name: "movies-genre-genreSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/movies/genre/[genreSlug]/index.vue")
  }
]
  },
  {
    name: _91genreSlug_93wfa3WstFBQAnLx1h_9XoZrENQFmPKYC8s_45oaJ30UJtsMeta?.name,
    path: "/en/movies/genre/:genreSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/movies/genre/[genreSlug].vue"),
    children: [
  {
    name: "movies-genre-genreSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/movies/genre/[genreSlug]/index.vue")
  }
]
  },
  {
    name: _91genreSlug_93wfa3WstFBQAnLx1h_9XoZrENQFmPKYC8s_45oaJ30UJtsMeta?.name,
    path: "/es/peliculas/generos/:genreSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/movies/genre/[genreSlug].vue"),
    children: [
  {
    name: "movies-genre-genreSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/movies/genre/[genreSlug]/index.vue")
  }
]
  },
  {
    name: "movies-genres___de",
    path: "/filme/genres",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/movies/genres.vue")
  },
  {
    name: "movies-genres___en",
    path: "/en/movies/genres",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/movies/genres.vue")
  },
  {
    name: "movies-genres___es",
    path: "/es/peliculas/generos",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/movies/genres.vue")
  },
  {
    name: "movies___de",
    path: "/filme",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/movies/index.vue")
  },
  {
    name: "movies___en",
    path: "/en/movies",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/movies/index.vue")
  },
  {
    name: "movies___es",
    path: "/es/peliculas",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/movies/index.vue")
  },
  {
    name: "person-personSlug___de",
    path: "/person/:personSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/person/[personSlug].vue")
  },
  {
    name: "person-personSlug___en",
    path: "/en/person/:personSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/person/[personSlug].vue")
  },
  {
    name: "person-personSlug___es",
    path: "/es/persona/:personSlug()",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/person/[personSlug].vue")
  },
  {
    name: "persons___de",
    path: "/personen",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/persons.vue")
  },
  {
    name: "persons___en",
    path: "/en/persons",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/persons.vue")
  },
  {
    name: "persons___es",
    path: "/es/personas",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/persons.vue")
  },
  {
    name: "search___de",
    path: "/suche",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/search.vue")
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/search.vue")
  },
  {
    name: "search___es",
    path: "/es/buscar",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/search.vue")
  },
  {
    name: "vouchers___de",
    path: "/gutscheine",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/vouchers.vue")
  },
  {
    name: "vouchers___en",
    path: "/en/vouchers",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/vouchers.vue")
  },
  {
    name: "vouchers___es",
    path: "/es/codigos",
    component: () => import("/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/pages/vouchers.vue")
  }
]