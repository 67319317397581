import revive_payload_client_HTQv_itGuzf7TNDrJwU53P6JGe7PUim4_NsNGlZ_I_k from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/node_modules/.pnpm/nuxt@3.16.0_@types+node@22.10.1_eslint@9.23.0_rollup@4.37.0_tsx@4.19.2_typescript@5.7.3_vite@6.2.3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_8nv3AVy8zQ_mlFdJeJ0a_gmhbHf_lA6EAiiU5jQVXGQ from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/node_modules/.pnpm/nuxt@3.16.0_@types+node@22.10.1_eslint@9.23.0_rollup@4.37.0_tsx@4.19.2_typescript@5.7.3_vite@6.2.3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_iolE8xuYIP_4m2QWhLE2th6Y99_6R0pDtkWOwBtRntQ from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/node_modules/.pnpm/nuxt@3.16.0_@types+node@22.10.1_eslint@9.23.0_rollup@4.37.0_tsx@4.19.2_typescript@5.7.3_vite@6.2.3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_jD2Pen_viW4LKltHvfbzti_TRN53HEUjXc0SukTzjBA from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/node_modules/.pnpm/nuxt@3.16.0_@types+node@22.10.1_eslint@9.23.0_rollup@4.37.0_tsx@4.19.2_typescript@5.7.3_vite@6.2.3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_4Y4Ny4xslsZv6C1WBH6jplwKlQPInwajD5Rq0GCcbzg from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/node_modules/.pnpm/nuxt@3.16.0_@types+node@22.10.1_eslint@9.23.0_rollup@4.37.0_tsx@4.19.2_typescript@5.7.3_vite@6.2.3/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_aRauxgjn00ntmKvIQpw0bG5jQNNVqXpjQg1yFKmhzVA from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/node_modules/.pnpm/nuxt@3.16.0_@types+node@22.10.1_eslint@9.23.0_rollup@4.37.0_tsx@4.19.2_typescript@5.7.3_vite@6.2.3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_oFZxKOIN__gIUenlc5JSfe28ciH8_JyPfj7tobXVamw from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/node_modules/.pnpm/nuxt@3.16.0_@types+node@22.10.1_eslint@9.23.0_rollup@4.37.0_tsx@4.19.2_typescript@5.7.3_vite@6.2.3/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_n84QehqjwdD68Nk6pb3epwEGbpKpDozogx5mlWJBZXs from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/node_modules/.pnpm/nuxt@3.16.0_@types+node@22.10.1_eslint@9.23.0_rollup@4.37.0_tsx@4.19.2_typescript@5.7.3_vite@6.2.3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/apps/kinoheld/.nuxt/components.plugin.mjs";
import prefetch_client__uB8bRwMEWbBwcxzTA7KJjmivNMKfCCK9n6RlsoifXg from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/node_modules/.pnpm/nuxt@3.16.0_@types+node@22.10.1_eslint@9.23.0_rollup@4.37.0_tsx@4.19.2_typescript@5.7.3_vite@6.2.3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import formkitPlugin_Kw9Zxpc32ie3g9uaRfiavVL004WiVJNxPefwwMCJMnU from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/apps/kinoheld/.nuxt/formkitPlugin.mjs";
import plugin_kXOneI6eFTkHjB_Cey0GkDF6S418YNknGSKHP_WFV3E from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/node_modules/.pnpm/@nuxt+icon@1.10.3_vite@6.2.3_vue@3.5.13/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_7hfu0a30eAZMeYyhGsKLR9muEiCTOD1sCcVw64MZiJc from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/node_modules/.pnpm/nuxt-graphql-client@0.2.43_@types+node@22.10.1_graphql-tag@2.12.6_typescript@5.7.3/node_modules/nuxt-graphql-client/dist/runtime/plugin.js";
import switch_locale_path_ssr_6TBjNlMzFfEwKavO_9PrG9v9Rky3Ve2V8UXuRnatvjg from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/node_modules/.pnpm/@nuxtjs+i18n@9.3.3_eslint@9.23.0_rollup@4.37.0_typescript@5.7.3_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_d5PanKfMexZTGqFS6pohZG7Zf7J1bHs6_E0cQsrrXUc from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/node_modules/.pnpm/@nuxtjs+i18n@9.3.3_eslint@9.23.0_rollup@4.37.0_typescript@5.7.3_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_PSB0to7WADZzdW_VaXxBqh4ddy7mBq6pOCVwXphGMDY from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/node_modules/.pnpm/@nuxtjs+i18n@9.3.3_eslint@9.23.0_rollup@4.37.0_typescript@5.7.3_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import google_tag_manager_d60AXsjCr3asCjutVrLJnj7f_nDw1v5gPuAW3gZ3DS0 from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/base/plugins/google-tag-manager.ts";
import sanitize_html_PPqV2MDJvntZ9lK0gacHe_GEUlY5RBEMAVZaxeKnuSs from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/base/plugins/sanitize-html.ts";
import sentry_client_rR4YwOPMa6CTIdHQMO7XVGKvVBgRGirCzXt2XuzcNqg from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/base/plugins/sentry.client.ts";
import widget_mode_lLDPOSYXnwdPlt3ZfP94Rx7dR55KdVo9jjAwleqpa1M from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/base/plugins/widget-mode.ts";
import google_publisher_tag_BbspqvK6jc0vRne9Ek2f2XsFqEvAxejVXmG_yYVaajk from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/layers/portal/plugins/google-publisher-tag.ts";
import ssg_detect_7_QXD2PLqjvsQU8TpvFiwnm5D9Adab2N3dSnCz_IaeQ from "/home/forge/deployments/kinoheld/dev/2025-04-02_16-03-09_00c9b9b/node_modules/.pnpm/@nuxtjs+i18n@9.3.3_eslint@9.23.0_rollup@4.37.0_typescript@5.7.3_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_HTQv_itGuzf7TNDrJwU53P6JGe7PUim4_NsNGlZ_I_k,
  unhead_8nv3AVy8zQ_mlFdJeJ0a_gmhbHf_lA6EAiiU5jQVXGQ,
  router_iolE8xuYIP_4m2QWhLE2th6Y99_6R0pDtkWOwBtRntQ,
  payload_client_jD2Pen_viW4LKltHvfbzti_TRN53HEUjXc0SukTzjBA,
  navigation_repaint_client_4Y4Ny4xslsZv6C1WBH6jplwKlQPInwajD5Rq0GCcbzg,
  check_outdated_build_client_aRauxgjn00ntmKvIQpw0bG5jQNNVqXpjQg1yFKmhzVA,
  view_transitions_client_oFZxKOIN__gIUenlc5JSfe28ciH8_JyPfj7tobXVamw,
  chunk_reload_client_n84QehqjwdD68Nk6pb3epwEGbpKpDozogx5mlWJBZXs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client__uB8bRwMEWbBwcxzTA7KJjmivNMKfCCK9n6RlsoifXg,
  formkitPlugin_Kw9Zxpc32ie3g9uaRfiavVL004WiVJNxPefwwMCJMnU,
  plugin_kXOneI6eFTkHjB_Cey0GkDF6S418YNknGSKHP_WFV3E,
  plugin_7hfu0a30eAZMeYyhGsKLR9muEiCTOD1sCcVw64MZiJc,
  switch_locale_path_ssr_6TBjNlMzFfEwKavO_9PrG9v9Rky3Ve2V8UXuRnatvjg,
  route_locale_detect_d5PanKfMexZTGqFS6pohZG7Zf7J1bHs6_E0cQsrrXUc,
  i18n_PSB0to7WADZzdW_VaXxBqh4ddy7mBq6pOCVwXphGMDY,
  google_tag_manager_d60AXsjCr3asCjutVrLJnj7f_nDw1v5gPuAW3gZ3DS0,
  sanitize_html_PPqV2MDJvntZ9lK0gacHe_GEUlY5RBEMAVZaxeKnuSs,
  sentry_client_rR4YwOPMa6CTIdHQMO7XVGKvVBgRGirCzXt2XuzcNqg,
  widget_mode_lLDPOSYXnwdPlt3ZfP94Rx7dR55KdVo9jjAwleqpa1M,
  google_publisher_tag_BbspqvK6jc0vRne9Ek2f2XsFqEvAxejVXmG_yYVaajk,
  ssg_detect_7_QXD2PLqjvsQU8TpvFiwnm5D9Adab2N3dSnCz_IaeQ
]